@import '../_variables';

.footer {
	padding: 50px 0;
	@include media-breakpoint-up(md) {
		padding: 70px 0;
	}
	@include media-breakpoint-up(lg) {
		padding: 100px 0;
	}

	&__logo {
		display: block;
		width: 100%;
		max-width: 42px;

		img {
			width: 100%;
		}
	}

	&__menu {

		ul {
			margin-bottom: 0;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;

			li {

				display: flex;
				flex-wrap: wrap;
				align-items: center;

				& + li {
					&:before {
						margin: 0 9px;
						display: block;
						opacity: 0.4;
						width: 2px;
						height: 11px;
						background: $gray-600;
						content: '';
					}
				}

				&.active {
					.footer__menu-link {
						color: $primary;
					}
				}

				a {
					font-size: 11px;
					color: $gray-600;

					&:hover {
						color: $primary;
					}
				}
			}

		}
	}

	&__copyright {
		font-size: 11px;
		color: rgba($gray-600, 0.53);

		a {
			color: rgba($gray-600, 0.53);

			&:hover {
				color: $primary;
			}
		}
	}
}
