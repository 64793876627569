@import '../_variables';

.header {
	padding: 28px 0;
	position: fixed;
	top: 8px;
	left: 0;
	z-index: 5;
	width: 100%;
	background: $white;
	transition: $transition-base;
	box-shadow: 0 1px 12px rgba(0, 0, 0, 0.05);
	@include media-breakpoint-down(sm) {
		padding: 0;
		top: auto;
		bottom: 0;
		opacity: 1 !important;
		visibility: visible !important;
		height: 70px;
		transform: none !important;
		animation: none !important;
		box-shadow: 0 1px 7px rgba(0, 0, 0, 0.1);
	}

	&__holder {
		@include media-breakpoint-down(sm) {
			z-index: 2;
			background: $white;
		}
	}

	.container {
		@include media-breakpoint-down(sm) {
			padding-right: 20px;
			padding-left: 20px;
		}
	}

	&__logo {
		margin-right: auto;
		position: relative;
		display: block;
		width: 100%;
		max-width: 156px;

		@include media-breakpoint-up(md) {
			max-width: 204px;
		}

		@include media-breakpoint-up(lg) {
			max-width: 170px;
		}
		@include media-breakpoint-up(xl) {
			max-width: 245px;
		}

		img {
			width: 100%;

			&:nth-child(2) {
				display: none;
			}
		}
	}

	&__button {

		@include media-breakpoint-up(lg) {
			min-width: 160px;
		}
	}

	&.is-fixed {
		.header {
			&__button {
				@extend .btn-success
			}
		}
	}

	&__hamburger {
		line-height: 1;
		@include media-breakpoint-up(md) {
			margin-right: 10px;
			z-index: 1;
		}
		@include media-breakpoint-up(lg) {
			margin-right: 35px;
			padding: 0 !important;
			z-index: 0;
			height: auto;
			border-radius: 0;
			background: transparent !important;
			box-shadow: none !important;
		}
		@include media-breakpoint-down(sm) {
			margin-right: -21px;
			flex-direction: column-reverse;
			width: 70px;
			height: 70px;
			font-family: $font-family-primary;
			font-size: 9px;
			font-weight: 700;
			color: $white;
			border-radius: 0;
			background: $primary !important;
		}

		cite {
			font-style: normal;

			&:nth-child(2) {
				display: none;
			}
		}

		.hamburger-inner {
			@include media-breakpoint-down(sm) {
				background: $white;
			}

			&:before,
			&:after {
				@include media-breakpoint-down(sm) {
					background: $white;
				}
			}
		}

		&.is-active {
			@include media-breakpoint-only(md) {
				height: auto;
				background: transparent !important;
				box-shadow: none;
			}

			cite {
				&:nth-child(1) {
					display: none;
				}

				&:nth-child(2) {
					display: block;
				}
			}
		}

		&:focus,
		&:hover {
			@include media-breakpoint-down(md) {
				background: transparent;
			}
			@include media-breakpoint-down(sm) {
				color: $white;
			}
		}
	}

	&__tel {
		margin-right: 18px;
		font-size: 14px;
		font-weight: 500;
		color: $body-color;
		@include media-breakpoint-up(md) {
			font-size: 16px;
		}
		@include media-breakpoint-up(xl) {
			margin-right: 25px;
		}
		@include media-breakpoint-up(xxl) {
			margin-right: 47px;
		}

		img {
			margin-right: 5px;
			width: 12px;
			@include media-breakpoint-up(xl) {
				margin-right: 10px;
			}
		}

		&:hover {
			color: $primary;
		}
	}

    &__nav {
        > ul {
            margin-bottom: 0;
            padding: 0 0 0 30px;
            position: relative;
            display: none;

            @include media-breakpoint-up(lg) {
                display: flex;
            }
            @include media-breakpoint-up(xl) {
                padding-left: 35px;
            }
            @include media-breakpoint-up(xxl) {
                padding-left: 47px;
            }

			> li {
				padding-top: 10px;
				padding-bottom: 10px;
			}

            li {
				position: relative;
                margin-right: 20px;
                @include media-breakpoint-up(xl) {
                    margin-right: 40px;
                }
                @include media-breakpoint-up(xxl) {
                    margin-right: 55px;
                }

                &.active > a,
                &:hover > a {
                    color: $primary;
                }

				&:hover ul {

					display: block;
				}

				ul {
					display: none;
					background: #fff;
					border-bottom: 5px solid $primary;
					border-radius: 4px;
					box-shadow: 0 3px 26px rgb(0 0 0 / 16%);
					left: 50%;
					min-width: 155px;
					padding: 15px 25px;
					position: absolute;
					top: 100%;
					transform: translateX(-50%);
					transition: .3s ease-in-out;

					li {
						margin-bottom: 10px;
						margin-right: 0px;
					}

					li a {
						font-size: 16px;
						line-height: 1.4;
						display: block;
					}

				}

                a {
                    font-size: 14px;
                    font-weight: 500;
                    text-transform: uppercase;
                    color: $body-color;
                    @include media-breakpoint-up(xl) {
                        font-size: 16px;
                    }
                }

            }

            &:before {
                position: absolute;
                top: 50%;
                left: 0;
                display: block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: $primary;
                transform: translateY(-50%);
                content: '';
            }

        }
    }

	&__close {
		position: absolute;
		top: 50%;
		left: calc(100% + 32px);
		z-index: 1;
		display: none;
		width: 56px;
		height: 56px;
		background: $white;
		transform: translateY(-50%);
		@media only screen and (max-width: 1825px) {
			left: 100%;
		}
		@media only screen and (max-width: 1770px) {
			margin-left: 10px;
			position: relative;
			top: 0;
			left: 0;
			transform: none;
		}

		//.hamburger-box {
		//	height: 24px;
		//}
		//
		//.hamburger-inner,
		//.hamburger-box {
		//	width: 24px;
		//
		//	&:before {
		//		width: 24px;
		//	}
		//}

		&:hover {
			background-color: $black;

			.hamburger-inner {
				background-color: $white;

				&:before,
				&:after {
					background-color: $white;
				}
			}
		}
	}

	.search-block {
		display: none;

		@include media-breakpoint-up(md) {
			margin-bottom: 50px;
		}

		@include media-breakpoint-up(lg) {
			margin-right: -30px;
			max-width: 382px;
			margin-bottom: 0;
		}
	}

	&__menu {
		position: fixed;
		top: 0;
		left: 0;
		display: none;
		width: 100%;
		height: calc(100vh - 70px);
		color: $white;
		background-color: $primary;
		overflow: hidden;
		@include background-image(menu-bg, svg, left bottom, no-repeat, 300px);

		@include media-breakpoint-up(md) {
			height: 100vh;
			background-size: 500px;
			background-position-y: calc(100% + 30px);
			background-position-x: -60px;
		}

		&::after {
			@include media-breakpoint-up(xl) {
				position: absolute;
				top: 0;
				right: -200px;
				left: 70%;
				display: block;
				height: 100%;
				background: rgba($black, 0.05);
				transform: skewX(-20deg);
				content: '';
			}
		}

		.search-block {
			display: block;
		}
	}

	&__menu-holder {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		overflow-y: scroll;

		@include media-breakpoint-up(md) {
			top: 110px;
			overflow-y: initial;
		}

		@include media-breakpoint-up(lg) {
			top: 170px;
		}

		@include media-breakpoint-up(xxl) {
			top: 260px;
		}


		@include media-breakpoint-down(sm) {
			padding: 40px 0 130px;
		}

	}

	&__menu-icon {
		width: 40px;
	}

	&__menu-items {

        > ul {
            margin: 0;
            @include media-breakpoint-up(md) {
                display: flex;
                flex-wrap: wrap;
            }

            > li {

                @include media-breakpoint-up(md) {
                    padding-right: 20px;
                    flex: 0 0 295px;
                }

				@include media-breakpoint-up(lg) {
					flex: 0 0 250px;
				}

				@include media-breakpoint-up(xl) {
					flex: 0 0 295px;
				}

                @include media-breakpoint-down(sm) {
                    margin-bottom: 25px;
                }

                &.active {
                    > a {
                        color: $white;
                    }
                }

                > a {
                    font-weight: 500;
                    color: $body-color;
                    @include media-breakpoint-down(md) {
                        font-size: 17px;
                    }
                    @include media-breakpoint-down(sm) {
                        font-size: 22px;
                        font-weight: 600;
                    }

                    &:hover {
                        color: $white;
                    }
                }

                > ul {
                    margin-top: 10px;
                    @include media-breakpoint-up(lg) {
                        margin-top: 15px;
                    }
                    @include media-breakpoint-up(xl) {
                        margin-top: 25px;
                    }

                    > li {

                        @include media-breakpoint-up(lg) {
                            margin-bottom: 8px;
                        }

                        > a {
                            font-size: 16px;
                            color: $white;
                            @include media-breakpoint-up(md) {
                                font-size: 14px;
                            }
                            @include media-breakpoint-up(lg) {
                                font-size: 15px;
                            }
                        }

                        &.active > a, > a:hover {
                            color: $black;
                        }
                    }

                }

            }

        }

	}

	&__menu-bottom {
		margin: auto 0 30px 0;
		font-size: 12px;
	}

	&__naw-item {

		@include media-breakpoint-up(lg) {
			margin-right: 30px;
		}

		@include media-breakpoint-up(xl) {
			margin-right: 70px;
		}

		strong {
			display: block;
		}

		a {
			color: $white;

			&:hover {
				color: $black;
			}
		}
	}

	&__social-item {
		a {
			margin: 0 3px;
			width: 28px;
			height: 28px;
			font-size: 12px;
			color: $primary;
			border-radius: 50%;
			background-color: $white;

			&:hover {
				color: $primary;
				background-color: $black;
			}
		}
	}

	&__menu-block {

		position: absolute;
		right: -170px;
		bottom: 0;

		@include media-breakpoint-up(xxxl) {
			right: -220px;
			bottom: -30px;
		}

		img {
			width: 432px;
			height: auto;

			@include media-breakpoint-up(xxxl) {
				width: 650px;
			}
		}

	}

	&__menu-video {

		padding: 9px;
		z-index: 1;
		border-radius: 13px;
		background: $white;
		box-shadow: 0 16px 36px rgba(0, 0, 0, 0.27);

		width: 330px;

		position: absolute;
		bottom: 45px;
		right: 0;

		display: none;

		@include media-breakpoint-up(md) {
			display: block;
		}

		@include media-breakpoint-up(xxxl) {
			width: 530px;
			padding: 10px;
			bottom: 85px;
			right: 100px;
		}

		img {
			border-radius: 5px;
			width: 100%;
		}
	}

	&__menu-play {
		position: absolute;
		top: 55%;
		left: 50%;
		color: $white;
		transform: translate(-50%, -50%);

		cite {
			width: 58px;
			height: 58px;
			font-size: 40px;
			border: 3px solid $white;
			transition: $transition-base;
			@include media-breakpoint-up(md) {
				width: 70px;
				height: 70px;
				font-size: 60px;
			}
			@include media-breakpoint-up(lg) {
				border-width: 6px;
			}
			@include media-breakpoint-up(xxl) {
				width: 92px;
				height: 92px;
				font-size: 75px;
			}
		}

		span {
			max-width: 160px;
			font-size: 19px;
			font-weight: 600;
			line-height: 1.2;
			text-transform: uppercase;
			@include media-breakpoint-down(md) {
				white-space: nowrap;
			}
		}

		&:hover {
			cite {
				transform: scale(1.05);
			}
		}
	}

	.btn {
		@include media-breakpoint-up(md) {
			padding: 0.5rem 1.5rem 0.4rem;
			height: 48px;
		}
		@include media-breakpoint-up(lg) {
			padding: 0.5rem $btn-padding-x 0.4rem;
			height: 55px;
		}
		@include media-breakpoint-up(lg) {
			height: 58px;
		}
	}

	&.is-active {
		.header__logo {

			@include media-breakpoint-up(md) {
				z-index: 1;
				opacity: 0.4;
				max-width: 244px;
			}

			img {
				&:nth-child(1) {
					@include media-breakpoint-up(md) {
						display: none;
					}
				}

				&:nth-child(2) {
					@include media-breakpoint-up(md) {
						display: block;
					}
				}
			}
		}

		.header__nav {
			@include media-breakpoint-up(lg) {
				display: none;
			}
		}

		.header__button {
			z-index: 1;
			color: $black;
			background: $white;

			&:hover {
				color: $white;
				background: $black;
			}
		}

		.header__close {
			@include media-breakpoint-up(lg) {
				display: flex;
			}
		}

		.search-block {
			@include media-breakpoint-up(lg) {
				display: block;
			}
		}

		.header__tel {
			@include media-breakpoint-up(lg) {
				display: none !important;
			}
		}
	}

	&.is-fixed {
		box-shadow: 0 1px 12px rgba($black, 0.11);
	}

	&--home {
		@include media-breakpoint-up(md) {
			box-shadow: none;
			background: transparent;
		}

		&.is-fixed {
			background: $white;
		}
	}
}
