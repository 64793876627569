@import '../_variables';

.usp-block {
	margin: 70px 0;
	z-index: 1;
	@include media-breakpoint-up(lg) {
		margin: 90px 0;
	}
	@include media-breakpoint-up(xl) {
		margin: 115px 0 128px;
	}

	h4 {
		margin-bottom: 21px;
		font-size: 28px;
		@include media-breakpoint-up(md) {
			font-size: 42px;
		}
		@include media-breakpoint-up(lg) {
			font-size: 46px;
		}
		@include media-breakpoint-down(sm) {
			text-transform: uppercase;
		}
	}

	p {
		margin-bottom: 20px;
		font-size: 14px;
		@include media-breakpoint-up(md) {
			margin-bottom: 35px;
			font-size: 17px;
		}
	}

	ol {
		margin-bottom: 45px;
		counter-reset: counter;

		li {
			margin-bottom: 10px;
			position: relative;
			font-size: 14px;
			font-weight: 500;
			@include media-breakpoint-up(md) {
				font-size: 18px;
			}
			@include media-breakpoint-up(lg) {
				padding-left: 30px;
			}
			@include media-breakpoint-up(xl) {
				font-size: 19px;
			}

			&:before {
				top: 0;
				left: 0;
				content: counter(counter) '. ';
				counter-increment: counter;
				@include media-breakpoint-up(lg) {
					position: absolute;
				}
			}
		}
	}

	&__content {
		@include media-breakpoint-down(md) {
			max-width: 550px;
		}
	}

	&__images {

		@include media-breakpoint-up(md) {
			margin-top: 30px;
		}

		@include media-breakpoint-up(xxl) {
			margin-top: 0;
		}

		img {
			width: 100%;
			border-radius: $border-radius-sm;
			box-shadow: 0 18px 42px rgba($black, 0.23);
		}
	}

	&__images-first {
		width: 331px;

		margin-left: auto;
		margin-right: -40px;

		@include media-breakpoint-up(md) {
			width: 490px;
			margin-right: 50px;
		}

		@include media-breakpoint-up(xxl) {
			width: 702px;
		}

	}

	&__images-second {
		width: 187px;

		margin-top: -80px;
		margin-right: auto;
		margin-left: -60px;


		@include media-breakpoint-up(md) {
			width: 276px;
			margin-left: 20px;
			margin-top: -120px;
		}

		@include media-breakpoint-up(xxl) {
			width: 397px;
			margin-top: -175px;
			margin-left: 0;
		}

	}

	&__images-third {
		width: 100px;

		margin-left: auto;
		margin-right: 25px;
		margin-top: -5px;

		@include media-breakpoint-up(md) {
			width: 148px;
			margin-right: 148px;
		}

		@include media-breakpoint-up(xxl) {
			width: 212px;
			margin-top: -13px;
			margin-right: 200px;
		}

	}

	&__element {
		top: -80px;
		left: calc(50% - 90px);
		z-index: -1;
		width: 700px;
		@include media-breakpoint-up(md) {
			top: 30px;
			left: calc(50% - 50px);
			width: 815px;
		}
		@include media-breakpoint-up(lg) {
			left: calc(50% + 170px);
		}
		@include media-breakpoint-up(xl) {
			top: -160px;
			left: calc(50% + 270px);
			width: 1585px;
		}
	}
}
