@import '../_variables';

.top-bar {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 6;
	width: 100%;
	height: 9px;
	font-size: 0;
	background: $white;
	transition: $transition-base;
	box-shadow: 0 3px 6px rgba($black, 0.16);

	&:after,
	&:before {
		position: absolute;
		top: 40%;
		left: 4px;
		width: 102px;
		height: 2px;
		border-radius: 2px;
		background: $primary;
		transform: translateY(-50%);
		content: '';
	}

	&:after {
		left: 110px;
		width: 22px;
		height: 17px;
		background-color: transparent;
		transform: translateY(0);
		@include background-image(nife, svg, center, no-repeat, 100%);
	}

	&.is-active {
		@include media-breakpoint-up(lg) {
			opacity: 0 !important;
			visibility: hidden !important;
		}
	}
}
