@import '../_variables';

.product-slider {
	margin: 50px 0;
	z-index: 2;

	@include media-breakpoint-up(md) {
		z-index: 1;
	}

	&__container {

		position: relative;

		&:after {
			position: absolute;
			top: -140px;
			right: -420px;
			left: auto;
			z-index: -1;
			display: block;
			width: 570px;
			height: 570px;
			border-radius: 50%;
			background: $gray-100;
			content: '';
			@include media-breakpoint-up(md) {
				width: 628px;
				height: 628px;
				right: -370px;
				top: -110px;
			}
			@include media-breakpoint-up(lg) {
				top: -150px;
				width: 895px;
				height: 895px;
				right: -600px;
			}
		}
	}



	h3 {
		@include media-breakpoint-down(md) {
			font-size: 44px;
		}
		@include media-breakpoint-down(sm) {
			font-size: 28px;
		}
	}

	.icon-swipe {
		margin: 0 22px;
		display: block;
		font-size: 30px;
	}

	form {
		margin-right: 8px;
		position: relative;
		width: 100%;
		max-width: 403px;
		@include media-breakpoint-down(md) {
			margin-right: auto;
		}

		input {
			padding: 5px 50px 5px 20px;
			width: 100%;
			height: 43px;
			font-size: 13px;
			border: 2px solid $gray-100;
			border-radius: 26px;
			@include media-breakpoint-up(md) {
				padding: 5px 60px 5px 25px;
				height: 51px;
				font-size: 16px;
			}
		}

		button {
			top: 0;
			right: 0;
			width: 55px;
			height: 100%;
			font-size: 28px;
			border: 0;
			background: transparent;
			cursor: pointer;

			span {
				display: block;
			}
		}
	}

	&__slides {
		overflow: initial;
	}

	&__slides-slide {
		height: auto;
		width: 280px !important;

		@include media-breakpoint-up(md) {
			width: 294px !important;
		}

		@include media-breakpoint-up(xl) {
			width: 366px !important;
		}

 	}

	.btn {
		min-width: 228px;
		@include media-breakpoint-up(md) {
			min-width: 311px;
		}
		@include media-breakpoint-down(sm) {
			height: 48px;
			font-size: 14px;
		}
	}
}
