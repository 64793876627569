@import '../_variables';

ul.pagination {

	display: flex;
	flex-wrap: wrap;
	margin: 20px 0;

	li {
		margin-right: 10px;
		margin-bottom: 10px;

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 43px;
			height: 43px;
			font-size: 13px;
			font-weight: 600;
			color: rgba($gray-800, 0.3);
			border: 2px solid $gray-100;
			border-radius: 50%;
			background: $white;

			&:hover {
				color: $white;
				border: 0;
				background: $primary;
				box-shadow: 0 8px 15px rgba($primary, 0.52);

				.material-icons {
					color: $white;
				}
			}
		}

		&:first-child {
			padding-left: 0;

			a {
				border-radius: 50%;
			}
		}

		&:last-child {
			padding-right: 0;
		}

		.material-icons {
			font-size: 240%;
			color: $gray-700;
			transition: $transition-base;
		}

		&.active {

			a {
				color: $white;
				border-color: $primary;
				background: $primary;
				box-shadow: 0 8px 15px rgba($primary, 0.52);
			}

			.material-icons {
				color: $white;
			}
		}
	}

}
