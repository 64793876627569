// Table of Content
// ===============================================
// # Welcome
// # Variables
// # Base
// # Critical
// # Fonts
// # Plugins
// # Vendors
//
// # Welcome
//   This file is used to centralize the imports
// =============================================== */
//
// Most of the following guidelines used to write css:
// http://cssguidelin.es
//
// # Variables
//   Globally-available variables and configs
// =============================================== */

@import 'variables';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/bootstrap';
@import '~jbox/dist/jBox.all.css';

//
// # Base
// Resets, grids, wrappers, body, typography and forms
// =============================================== */

@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/buttons';
@import 'base/base';
@import 'base/reset';
@import 'base/btn';

//
// # Vendors
//   Vendor includes
// =============================================== */
@import 'vendor';
@import 'base/form';
@import '~@wallbrinkcrossmedia/wcm-es6-scroll-tracker';

//
// # Critical
//   The critical components to load inline
// =============================================== */
@import 'components/loader';
@import 'components/filter-block';
@import 'components/search-block';
@import 'components/card-product';
@import 'components/breadcrumb';
@import 'components/content';
@import 'components/card-team';
@import 'components/scroll-tracker';
@import 'components/contact-us';
@import 'components/media-card';
@import 'components/pagination';
@import 'components/container';
@import 'components/form';

//
// # Sections
//   Load sections
// =============================================== */

@import 'sections/top-bar';
@import 'sections/header';
@import 'sections/intro';
@import 'sections/about-intro';
@import 'sections/content-slider';
@import 'sections/check-list';
@import 'sections/product-slider';
@import 'sections/usp-block';
@import 'sections/cta-block';
@import 'sections/parallax-img';
@import 'sections/banner';
@import 'sections/footer';
@import 'sections/team';
@import 'sections/team-slider';
@import 'sections/product';
@import 'sections/support-block';

//
// # Fonts
//   Load custom fonts
// =============================================== */
@import url('https://fonts.googleapis.com/css2?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Material+Icons+Round');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import '../fonts/_font.scss';

//
// # Plugins
//   Apply theme styling to plugins
// =============================================== */
//@import 'plugins/**/*.scss';