@import '../_variables';

.team-slider {
	margin-bottom: 40px;
	z-index: 1;
	@include media-breakpoint-up(md) {
		margin-bottom: 80px;
		padding-top: 10px;
	}
	@include media-breakpoint-down(sm) {
		padding-bottom: 100px;
	}

	h2 {
		margin-bottom: 20px;
		font-weight: 600;
		@include media-breakpoint-down(sm) {
			font-size: 30px;
		}
	}

	&__bg {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		overflow: hidden;
		@include media-breakpoint-up(md) {
			bottom: 153px;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__slides {
		padding: 0 50px;
		@include media-breakpoint-up(md) {
			padding: 0 15px;
		}
		@media (min-width: 1320px) {
			padding: 0 calc(50% - 460px) 0 calc(50% - 645px);
		}
	}

	&__slides-slide {
		height: auto;
	}

	&__control {
		@include media-breakpoint-up(md) {
			padding-top: 7px;
		}

		.btn {
			margin: 0 10px;
			height: 43px;
			font-size: 12px;
			font-weight: 700;
		}

		.swiper-button-prev,
		.swiper-button-next {
			margin: 0;
			right: auto;
			left: auto;
			width: 43px;
			height: 43px;
			border: 2px solid $gray-100;
			background: $white;
			box-shadow: none;

			&:before {
				color: $black;
			}
		}
	}
}
