@import '../_variables';

.filter-block {

	position: relative;
	z-index: 1;
	width: 100%;

	.flitzformfilters-menu__header,
	.flitzformfilters-menu__footer,
	.product__content-filter {
		display: none !important;
	}

	@include media-breakpoint-down(md) {
		padding: 15px 0;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 6;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		opacity: 0;
		visibility: hidden;
		height: 100vh;
		background: rgba($dark, 0.71);
		transition: $transition-base;
		overflow: hidden;
	}
	@include media-breakpoint-down(sm) {
		height: calc(100% - 70px);
	}

	&__holder {

		height: 100%;
		overflow-y: scroll;

		@include media-breakpoint-down(md) {
			width: 370px;
			border-radius: 0 25px 25px 0;
			background: $white;
			transform: translateX(-100%);
		}

		@include media-breakpoint-down(sm) {
			width: 300px;
		}

		@include media-breakpoint-up(lg) {
			overflow: initial;
			padding: 0;
		}

	}

	&__close {
		top: 23px;
		right: 25px;
		z-index: 1;
		font-size: 35px;
		color: $dark;
	}

	&__box {
		padding: 32px 28px 0;
		@include media-breakpoint-up(md) {
			padding: 32px 28px;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 30px;
		}
		@include media-breakpoint-down(md) {
			opacity: 1 !important;
			visibility: visible !important;
			animation: none !important;
			box-shadow: none !important;
		}

		h5 {
			font-size: 18px;
		}
	}

	.form-text {
		margin-right: 8px;
		position: relative;
		width: 100%;
		max-width: 403px;
		@include media-breakpoint-down(md) {
			margin-right: auto;
		}

		input {
			padding: 5px 45px 5px 19px;
			width: 100%;
			height: 40px;
			font-size: 12px;
			font-style: italic;
			border: 1px solid $gray-100;
			border-radius: 20px;
		}

		button {
			top: 0;
			right: 0;
			width: 55px;
			height: 100%;
			font-size: 28px;
			border: 0;
			background: transparent;
			cursor: pointer;

			span {
				display: block;
			}
		}
	}

	&__clear {
		height: 41px;
		font-size: 11px;
		font-weight: 700;
	}

	&__reset {
		font-size: 11px;
		color: $black;
	}

	&.is-active {
		@include media-breakpoint-down(md) {
			opacity: 1;
			visibility: visible;
			overflow: auto;
		}

		.filter-block__holder {
			@include media-breakpoint-down(md) {
				transform: translateX(0);
			}
		}
	}

	&.is-loaded {
		.filter-block__holder {
			@include media-breakpoint-down(md) {
				transition: $transition-base;
			}
		}
	}
}
