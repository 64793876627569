form.formbuilder-form .form-group.has-error .form-control {
  border-color: #f03;
  background-color: #ffcad8;
}

form.formbuilder-form .form-field-error-label {
  font-size: .8rem;
  font-style: italic;
  color: #f03;
  margin-top: 0.5rem;
}