@import '../_variables';

.intro {

	@include media-breakpoint-up(md) {
		margin-top: -114px;
		padding: 244px 0 0;
		height: 98vh;
		max-height: 980px;
		display: flex;
		align-items: flex-end;
	}

	@include media-breakpoint-up(lg) {
		margin-top: -120px;
		padding: 114px 0 0 0;
		align-items: center;
	}

	@include media-breakpoint-up(xl) {
		margin-top: -123px;
	}

	@include media-breakpoint-up(xxl) {
		padding: 323px 0 280px
	}

	&:after {
		@include media-breakpoint-up(xl) {
			position: absolute;
			top: 0;
			right: -600px;
			left: 70%;
			display: block;
			height: 100%;
			background: $gray-200;
			transform: skewX(45deg);
			content: '';

		}
	}

	h6 {
		font-size: 13px;
		font-weight: 600;
		@include media-breakpoint-up(md) {
			font-size: 19px;
		}
	}

	h1 {
		font-size: 25px;
		line-height: 1.1;
		@include media-breakpoint-up(md) {
			font-size: 46px;
		}
		@include media-breakpoint-up(lg) {
			font-size: 50px;
		}
		@include media-breakpoint-up(xl) {
			font-size: 55px;
		}
		@include media-breakpoint-down(sm) {
			text-transform: uppercase;
		}
	}

	&__logo {
		top: 35px;
		z-index: 2;
		display: block;
		width: 40px;
	}

	&__bg {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 240px;

		@include media-breakpoint-up(md) {
			height: 98vh;
			max-height: 980px;
		}

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			background: rgba($gray-300, 0.68);
			content: '';
		}

		&:after {
			@include media-breakpoint-up(lg) {
				position: absolute;
				bottom: 0;
				left: 0;
				display: block;
				width: 100%;
				height: 117px;
				background: rgba($white, 0.43);
				content: '';
			}
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;

			@include media-breakpoint-up(md) {
				height: 98vh;
				object-position: -200px 0;
			}

			@include media-breakpoint-up(xxl) {
				object-position: center;
			}

		}
	}

	&__holder {

		width: 100%;
		margin-bottom: 30px;

		@include media-breakpoint-up(md) {
			margin-bottom: 0;
		}

	}

	&__parson {

		position: relative;
		z-index: 2;

		display: flex;
		align-items: flex-end;
		justify-content: center;

		height: 240px;

		@include media-breakpoint-up(md) {
			height: auto;
			margin-right: -170px !important;
		}

		@include media-breakpoint-up(lg) {
			margin-right: 0 !important;
			left: 50%;
			margin-left: -225px !important;
			position: absolute;
			bottom: 0;
		}

		@include media-breakpoint-up(xxl) {
			margin-left: -325px !important;
		}

		img {
			width: 167px;

			@include media-breakpoint-up(md) {
				width: 586px;
				max-width: initial;
			}

			@include media-breakpoint-up(lg) {
				width: 450px;
			}

			@include media-breakpoint-up(xxl) {
				width: 650px;
			}

		}

	}

	&__parson-shadow {
		right: -25px;
		bottom: -28px;
		z-index: -1;
		width: 242px;
		height: 300px;

		img {
			width: 100%;
		}
	}

	&__content {
		z-index: 2;

		@include media-breakpoint-up(md) {
			padding-bottom: 70px;
		}

		@include media-breakpoint-up(lg) {
			max-width: 430px;
		}

		p {
			margin-bottom: 15px;
			@include media-breakpoint-up(lg) {
				margin-bottom: 30px;
			}
			@include media-breakpoint-only(md) {
				max-width: 90%;
			}
		}
	}

	&__video {
		margin-top: 30px;
		padding: 10px;
		position: relative;
		z-index: 2;
		display: block;
		width: 100%;
		border-radius: 13px;
		background: $white;
		box-shadow: 0 16px 36px rgba(0, 0, 0, 0.27);

		@include media-breakpoint-up(md) {
			margin-top: 90px;
			max-width: 365px;
		}
		@include media-breakpoint-up(lg) {
			margin-top: 30px;

		}
		@include media-breakpoint-up(xl) {
			padding: 17px;
			max-width: 555px;
		}
		@include media-breakpoint-up(xxxl) {
			margin-right: -20px;
		}

		img {
			width: 100%;
			border-radius: 5px;
			@include media-breakpoint-only(md) {
				height: 212px;
				object-fit: cover;
			}
		}
	}

	&__video-play {
		position: absolute;
		top: 55%;
		left: 50%;
		color: $white;
		transform: translate(-50%, -50%);

		cite {
			width: 58px;
			height: 58px;
			font-size: 40px;
			border: 3px solid $white;
			@include media-breakpoint-up(md) {
				width: 70px;
				height: 70px;
				font-size: 60px;
			}
			@include media-breakpoint-up(xl) {
				border-width: 6px;
			}
			@include media-breakpoint-up(xxl) {
				width: 110px;
				height: 110px;
				font-size: 95px;
			}
		}

		span {
			max-width: 160px;
			font-size: 13px;
			font-weight: 600;
			line-height: 1.2;
			text-transform: uppercase;
			@include media-breakpoint-down(md) {
				white-space: nowrap;
			}
			@include media-breakpoint-up(md) {
				font-size: 19px;
			}
		}
	}

	&__element {

		right: 0;
		bottom: 0;
		top: 125px;
		z-index: 1;
		width: 50%;
		height: 115px;
		background-color: $primary;

		@include background-image(intro-wm, svg, calc(100% + 50px) calc(100% + 100px), no-repeat);

		background-position: 60px 20px;
		background-size: 120px;

		@include media-breakpoint-up(md) {
			top: auto;
			bottom: 0;
			left: 0;
			height: 250px;
			right: 0;
			width: 100%;
		}

		@include media-breakpoint-up(lg) {
			width: 50%;
			left: auto;
			right: 0;

			background-position: 270px 20px;
			background-size: 300px;
		}

		@include media-breakpoint-up(xxl) {
			height: 400px;

			background-position: 450px 50px;
			background-size: 470px;
		}

	}

	&__down {
		padding-bottom: 15px;
		top: calc(100% + 12px);
		left: 50%;
		z-index: 2;
		width: 103px;
		height: 103px;
		font-size: 40px;
		color: $black;
		background: $white;
		transform: translate(-50%, -50%);
	}

	&__scroll {
		margin-top: 5px;
		z-index: 2;

		a {
			display: inline-block;
			opacity: 0.27;
			font-size: 40px;
			color: $body-color;

			&:hover {
				opacity: 1;
			}
		}
	}

	.search-block {
		@include media-breakpoint-up(md) {
			max-width: 405px;
		}

		input {

			@include media-breakpoint-up(md) {
				box-shadow: 0 14px 41px rgba(0, 0, 0, 0.3);
			}

			@include media-breakpoint-up(xl) {
				padding-right: 50px;
				padding-left: 130px;
				height: 68px;
				background-position: calc(100% - 30px);
				background-size: 23px;
			}
		}

		&__submit-btn {
			@include media-breakpoint-up(xl) {
				width: 105px;
				border-radius: 34px 0 20px 34px;
			}
		}
	}
}
