.scroll-tracker {
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  padding: 4px 5px;
  .tracker {
    position: relative;
    border-radius: 2px;
    &::after {

      content: '';
      display: block;

      position: absolute;
      right: -24px;
      width: 22px;
      height: 17px;
      background-color: transparent;
      transform: translateY(0);
      @include background-image(nife, svg, center, no-repeat, 100%);
    }
  }
}