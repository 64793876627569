//
// swiper
//
// Table of Content
// ===============================================
// # Variables
// # Imports
// # Overrides */

// # Variables
// =============================================== */

// # Imports
// =============================================== */
@import '~swiper/swiper-vars';
@import '~swiper/swiper';

// # Overrides
// =============================================== */
.swiper-button-prev,
.swiper-button-next {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    font-size: 0;
    position: relative;
    background: $primary;
    transition: $transition-base;
    @include media-breakpoint-down(sm){
        width: 43px;
        height: 43px;
    }

    .product-slider & {
        margin: 0 0 0 10px;
        position: relative;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        font-size: 36px;
        color: $white;
        font-family: $font-family-icon;
        transform: translate(-50%, -50%);
        line-height: 1.0;
        @include media-breakpoint-down(sm){
            font-size: 30px;
        }
    }

    &:after {
        display: none;
    }

    &.swiper-button-disabled {
        opacity: .3;
        background: $white;
        border: 2px solid $gray-100;
        cursor: not-allowed;
        &:before {
            color: $black;
        }
    }
}

.swiper-button-prev {
    .product-slider & {
        left: auto;
    }

    &:before {
        content: '\e5cb';
    }
}

.swiper-button-next {
    .product-slider & {
        right: auto;
    }

    &:before {
        content: '\e5cc';
    }
}

.swiper-pagination {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .content-slider & {
        z-index: 1;
        position: absolute;
        background: $primary;
        text-align: center;
        border-radius: 7px 7px 0 0;
        padding: 5px 0;
        @include media-breakpoint-up(lg){
            bottom: 0;
            left: 0;
            width: 47px;
            display: block;
            transform: translateX(-50%);
        }
        @include media-breakpoint-down(md){
            right: 0;
            top: 0;
            width: auto;
            bottom: auto;
            left: auto;
            height: 40px;
            padding: 0 5px;
            border-radius: 7px 0 0 7px;
            transform: translateY(-50%);
        }
        @include media-breakpoint-down(sm){
            top: auto;
            bottom: 0;
            padding: 0 8px;
            transform: translateY(50%);
        }
    }

    .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        cursor: pointer;
        opacity: 1;
        background: darken(saturate($primary, 5.40), 8.04);
        @include media-breakpoint-up(lg){
            width: 10px;
            height: 10px;
            display: block;
        }

        .content-slider & {
            margin: 0 4px;
            @include media-breakpoint-up(md){
                margin: 0 8px;
            }
            @include media-breakpoint-up(lg){
                margin: 12px auto;
            }
        }

        &.swiper-pagination-bullet-active {
            background: $gray-600;

            .content-slider & {
               background: $white;
            }
        }
    }
}
