@import '../_variables';

.card-product {
	width: 100%;

	img {
		width: 100%;
		transition: 1s ease-in-out transform;
	}

	&__image-label {
		padding: 8px 10px 5px;
		right: 0;
		bottom: 0;
		width: 125px;
		height: 53px;
		font-size: 12px;
		line-height: 1.1;
		@include background-image(label-icon, svg, calc(100% + 15px) -13px, no-repeat);
	}

	&__content {
		padding: 30px 32px 20px;
		color: $body-color;
		@include media-breakpoint-up(md) {
			padding: 30px 55px 20px;
		}
		@include media-breakpoint-up(lg) {
			padding: 47px 55px 20px;
		}

		h6 {
			margin-bottom: 4px;
			@include media-breakpoint-down(md) {
				font-size: 18px;
			}
		}

		p {
			font-size: 12px;
			color: $gray-500;
			@include media-breakpoint-up(md) {
				font-size: 16px;
			}
		}
	}

	&:hover {
		img {
			transform: scale(1.05) rotate(1deg);
		}
	}
}
