@import '../_variables';

.cta-block {
	z-index: 1;
	font-size: 14px;
	line-height: 1.93;
	@include media-breakpoint-up(md) {
		font-size: 17px;
		line-height: 1.6;
	}

	a {
		&:not(.btn) {
			color: $white;

			&:hover {
				color: $black;
			}
		}
	}

	h4 {
		line-height: 1.25;
		@include media-breakpoint-down(md) {
			font-size: 38px;
		}
		@include media-breakpoint-down(sm) {
			font-size: 28px;
			line-height: 1.22;
		}
	}

	&__holder {
		padding: 62px 85px 62px 75px;
		max-width: 1100px;
		@include background-image(cta-wm, svg, left bottom, no-repeat);
		@include media-breakpoint-only(md) {
			background-size: 385px;
		}
		@include media-breakpoint-down(sm) {
			padding: 50px 35px;
			@include background-image(cta-wm-m, svg, left 60px, no-repeat);
		}
	}

	&__content {
		@include media-breakpoint-up(lg) {
			max-width: 520px;
		}

		p {
			margin-bottom: 30px;
		}

		.btn-white {
			box-shadow: 0 5px 42px rgba($primary, 0.35);
			@include media-breakpoint-down(sm) {
				font-size: 14px;
			}
		}
	}

	&__address {
		margin-bottom: 37px;
		padding-top: 10px;
		padding-bottom: 35px;
		border-bottom: 3px solid rgba($white, 0.14);
		@include media-breakpoint-down(md) {
			max-width: 250px;
		}
	}

	&--page {
		.cta-block__holder {
			max-width: none;
		}
	}
}
