@import '../_variables';

.check-list {
	margin: 50px 0;
	position: relative;
	@include media-breakpoint-up(lg) {
		margin: 100px 0;
	}
	@include media-breakpoint-up(xl) {
		margin: 140px 0;
	}

	ul {
		@include check-list;
		@include check-list--alt;

		li {
			font-size: 13px;
			font-weight: 500;
			line-height: 1.8;
			@include media-breakpoint-up(md) {
				font-size: 16px;
			}

			.js-flitz-animated-count {
				color: $primary;
				font-weight: 700;
			}

		}
	}
}
