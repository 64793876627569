// Table of Content
// ===============================================
// # Variables
// # Font */

// # Variables
// =============================================== */
$icomoon-code: '5hso2p';

// # Font
// =============================================== */

@font-face {
	font-family: 'icomoon';
	font-weight: normal;
	font-style: normal;
	src: url('icomoon.eot?#{$icomoon-code}');
	src: url('icomoon.eot?#{$icomoon-code}#iefix') format('embedded-opentype'), url('icomoon.ttf?#{$icomoon-code}') format('truetype'), url('icomoon.woff?#{$icomoon-code}') format('woff'), url('icomoon.svg?#{$icomoon-code}#icomoon') format('svg');
	font-display: block;
}


[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }

  .icon-linkedin:before {
	content: "\e906";
  }
  .icon-facebook:before {
	content: "\e907";
  }
  .icon-sitemap:before {
	content: "\e905";
  }
  .icon-twitter:before {
	content: "\e90a";
  }
  .icon-swipe:before {
	content: "\e911";
  }
