@import '../_variables';

.about-intro {

	padding-top: 20px;
	margin-top: -30px;

	@include media-breakpoint-up(md) {
		padding-top: 50px;
		margin-top: 0;
	}
	@include media-breakpoint-up(xl) {
		padding-top: 140px;
	}

	&:before {
		position: absolute;
		bottom: -120px;
		left: calc(50% - 1160px);
		z-index: 2;
		display: block;
		width: 410px;
		height: 113px;
		border-radius: 50%;
		background: $primary;
		transform: rotate(-14deg);
		content: '';
	}

	&__element {
		z-index: 1;
		width: 630px;
		top: -50px;
		left: -475px;

		@include media-breakpoint-up(md) {
			z-index: 0;
			width: 730px;
			top: -70px;
			left: -520px;
		}

		@include media-breakpoint-up(lg) {
			width: 1200px;
			top: -171px;
			left: -767px;
		}

		@include media-breakpoint-up(xxxl) {
			width: 1740px;
			left: -1300px;
			top: -199px;
			z-index: 1;
		}

	}

	&__image {

		z-index: 1;


		@include media-breakpoint-up(lg) {
			width: calc(50% + 50px);
		}

		img {

			max-width: initial;
			width: 485px;
			margin-left: -95px;

			@include media-breakpoint-up(sm) {
				margin: 0 auto;
			}

			@include media-breakpoint-up(md) {
				max-width: 100%;
				width: auto;
			}

			@include media-breakpoint-up(lg) {
				width: 600px;
				max-width: initial;
				margin-left: -70px;
			}

			@include media-breakpoint-up(xl) {
				width: 750px;
				max-width: initial;
				margin-left: -80px;
			}

			@include media-breakpoint-up(xxxl) {
				width: 1152px;
				margin-left: -250px;
				margin-top: -70px;
			}

		}
	}

	&__content {
		z-index: 1;
		font-size: 17px;

		@include media-breakpoint-up(lg) {
			width: calc(50% - 50px);
		}

		@include media-breakpoint-down(md) {
			padding-bottom: 70px;
		}
		@include media-breakpoint-down(sm) {
			font-size: 14px;
		}

		h2 {
			margin-bottom: 20px;
			@include media-breakpoint-down(md) {
				font-size: 42px;
			}
			@include media-breakpoint-down(sm) {
				font-size: 32px;
			}
		}

		ul {
			@include check-list;
			margin-bottom: 53px;
			@include media-breakpoint-down(md) {
				margin: 0 auto 40px;
				max-width: 460px;
				text-align: left;
			}

			li {
				font-size: 14px;
				@include media-breakpoint-up(md) {
					font-size: 16px;
				}

				.js-flitz-animated-count {
					color: $primary;
					font-weight: 700;
				}

			}
		}

		p {
			line-height: 27px;
		}

	}

	&__content-align {
		max-width: 750px;
		@include media-breakpoint-up(lg) {
			padding-bottom: 50px;
			padding-left: 30px;
		}
		@include media-breakpoint-up(xxl) {
			padding-left: 118px;
		}
		@include media-breakpoint-only(md) {
			padding-right: 60px;
			padding-left: 60px;
		}
	}
}
