@import '../_variables';

.product {

	margin-bottom: 100px;
	z-index: 1;

	@include media-breakpoint-up(md) {
		margin-bottom: 70px;
	}
	@include media-breakpoint-up(xl) {
		margin-bottom: 80px;
	}

	&__content {
		padding: 35px 30px 0;
		@include media-breakpoint-up(md) {
			padding: 40px 55px;
		}
		@include media-breakpoint-up(xl) {
			padding: 70px;
		}
		@include media-breakpoint-up(xxl) {
			padding: 62px 75px;
		}

		h3 {
			@include media-breakpoint-down(md) {
				font-size: 36px;
			}
		}

		select {
			padding: 5px 30px 5px 15px;
			width: calc(50% - 5px);
			height: 38px;
			font-size: 11px;
			font-style: italic;
			color: $gray-600;
			border: 1px solid rgba($black, 0.05);
			border-radius: 20px;
			appearance: none;
			@include background-image(select, svg, calc(100% - 15px), no-repeat);
		}
	}

	&__content-filter {
		padding: 5px 20px;
		right: 70px;
		bottom: 80px;
		z-index: 4;
		height: 50px;
		font-size: 14px;
		font-weight: 600;
		text-transform: uppercase;
		border-radius: 9px;
		box-shadow: 0 0 26px rgba($black, 0.09);
		@include media-breakpoint-up(md) {
			bottom: 9px;
		}

		span {
			position: absolute;
			top: -10px;
			right: -10px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 19px;
			height: 19px;
			font-size: 12px;
			font-weight: 600;
			color: $white;
			border-radius: 50%;
			background: $black;
		}
	}

	&__block {
		border-radius: 14px;

		img {
			width: 100%;
		}
	}

	&__block-text {
		padding: 20px;
		top: 35px;
		left: 0;
		width: calc(100% - 20px);
		color: $white;
		background: rgba($black, 0.71);
		@include media-breakpoint-up(xl) {
			width: calc(100% - 42px);
		}
		@include media-breakpoint-up(xxl) {
			padding: 33px 50px 20px 39px;
		}

		h5 {
			margin-bottom: 5px;
			font-size: 20px;
			@include media-breakpoint-up(xl) {
				font-size: 25px;
			}
		}

		p {
			font-size: 16px;
			line-height: 1.5;
		}
	}

	.card-product__content {
		@include media-breakpoint-up(xl) {
			padding: 53px 55px 46px;
		}
	}
}
