@import '../_variables';

.support-block {

	right: 0;
	bottom: 80px;
	z-index: 5;

	width: 100%;
	max-width: 320px;
	color: $black;
	background-color: $white;
	box-shadow: 0 3px 25px rgba($black, 0.25);
	@include background-image(support-wm, svg, 70%, no-repeat);

	border-radius: 9px 0 0 9px;
	transform: translateX(270px);
	transition: 700ms ease;

	&--active {

		&.support-block {
			transform: translateX(0);
		}

		.support-block {

			&__close-btn {
				transform: scale(1);
				transition: 700ms;
			}

			&__content {
				padding: 15px 100px 35px 20px;

				p {
					display: block;
				}

			}

			&__content-tel {
				background: transparent;
				border-radius: 0;
				box-shadow: none;

				width: auto;
				height: auto;

				padding: 0;
				margin-top: 5px;

				justify-content: flex-start;

				.material-icons, span {
					color: $primary;
				}
			}

			&__content-text {
				display: inline;
			}

		}
	}

	@include media-breakpoint-up(md) {
		bottom: 20px;
	}

	&__close-btn {
		transform: scale(0);
		position: absolute;
		top: -10px;
		left: -10px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $primary;
		border-radius: 50%;
		height: 24px;
		width: 24px;
		text-align: center;
		font-size: 16px;
		font-weight: 700;
		cursor: pointer;
		line-height: 1.0;
		color: #fff;
		transition: 500ms;
	}

	&__content {

		padding: 0;

		p {
			display: none;
			margin: 0;
			font-size: 18px;
			font-weight: 700;
			line-height: 1.3;
		}

		a {
			font-size: 14px;
			font-weight: 700;
			font-style: italic;

			display: flex;
			align-items: center;
			flex-wrap: nowrap;

			.material-icons {
				font-size: 28px;
				font-style: italic;
				margin-right: 5px;
				color: #fff;
			}

		}

	}

	&__content-tel {

		display: flex;
		align-items: center;
		justify-content: center;
		width: 55px;
		height: 50px;
		color: $white;
		border-radius: 9px 0 0 9px;
		background: $primary;
		box-shadow: 0 0 25px rgba($black, 0.09);

		transition: none;

		@include media-breakpoint-up(md) {
			font-size: 25px;
			color: $primary;
			background-color: #2a9e3d;
			border-color: #2a9e3d;
		}

	}

	&__content-text {
		display: none;
	}

	&__image {
		right: -44px;
		bottom: 0;
		width: 240px;

		img {
			width: 100%;
		}
	}
}
