@import '../_variables';

.contact-us {
	margin-bottom: 50px;
	z-index: 2;
	@include media-breakpoint-up(lg) {
		margin-bottom: 70px;
	}
	@include media-breakpoint-up(xl) {
		margin-bottom: 80px;
	}

	&__element {
		top: -65px;
		right: -15px;
		z-index: 1;
		max-width: 350px;
		@include media-breakpoint-up(lg) {
			max-width: 450px;
		}
		@include media-breakpoint-up(xl) {
			max-width: 520px;
		}
		@include media-breakpoint-up(xxl) {
			right: calc(50% - 826px);
			max-width: 610px;
		}
	}

	&__form {
		padding: 40px 40px 60px;
		@include media-breakpoint-up(md) {
			padding: 62px;
		}
		@include media-breakpoint-up(lg) {
			padding: 62px 70px 62px 113px;
		}

		h2 {
			margin-bottom: 17px;
			@include media-breakpoint-down(md) {
				font-size: 42px;
			}
			@include media-breakpoint-down(sm) {
				margin-bottom: 10px;
				font-size: 22px;
			}
		}

		p {
			margin-bottom: 27px;
			font-size: 12px;
			@include media-breakpoint-up(md) {
				font-size: 14px;
			}
		}

		button {
			@include media-breakpoint-down(sm) {
				width: 100%;
			}
		}
	}

	&__side {
		width: 100%;
		@include media-breakpoint-up(lg) {
			width: calc(100% - 20px);
			box-shadow: 0 3px 34px rgba(0, 0, 0, 0.13);
		}
	}

	&__side-info {
		padding: 53px 62px 63px;
		font-size: 17px;
		line-height: 1.6;
		background: $gray-100;
		@include media-breakpoint-down(md) {
			padding-bottom: 40px;
		}

		p {
			margin-bottom: 10px;
			@include media-breakpoint-up(lg) {
				margin-bottom: 27px;
			}
		}

		a {
			color: $body-color;

			&:hover {
				color: $primary;
			}
		}
	}

	&__side-social {
		padding-top: 6px;

		a {
			margin-right: 6px;
			width: 28px;
			height: 28px;
			font-size: 12px;
			color: $white;
			background: $primary;

			&:hover {
				color: $white;
				background: $black;
			}
		}
	}

	&__side-img {
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			@include media-breakpoint-down(sm) {
				min-height: 155px;
				border-radius: 6px 6px 0 0;
			}
		}
	}

	&__side-address {
		justify-content: center;
		width: 100%;
		height: 52px;
		font-size: 12px;

		span {
			margin-right: 10px;
			font-size: 200%;
		}
	}

	&__side-map {
		> div {
			display: block;
			width: 100%;
			height: 155px;
			border: 0;
			@include media-breakpoint-up(md) {
				height: 300px;
			}
			@include media-breakpoint-up(lg) {
				height: 440px;
			}
		}
	}

	& + .team-slider {
		.team-slider__bg {
			top: -85px;
			@include media-breakpoint-up(md) {
				top: -147px;
			}
		}
	}
}
