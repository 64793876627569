@import '../_variables';
@import '../mixins/content';

.content {
	margin-bottom: 50px;
	z-index: 1;

	@include media-breakpoint-up(lg) {
		margin-bottom: 70px;
	}
	@include media-breakpoint-up(xl) {
		margin-bottom: 80px;
	}

	&__element {
		top: -65px;
		right: -15px;
		max-width: 350px;
		@include media-breakpoint-up(lg) {
			max-width: 450px;
		}
		@include media-breakpoint-up(xl) {
			max-width: 520px;
		}
		@include media-breakpoint-up(xxl) {
			right: calc(50% - 826px);
			max-width: 610px;
		}
	}

	&__holder {
		padding: 35px 35px 60px;
		max-width: 1250px;
		margin: 0 auto;
		@include media-breakpoint-up(md) {
			padding: 40px 60px 80px;
		}
		@include media-breakpoint-up(lg) {
			padding: 80px;
		}
		@include media-breakpoint-up(xl) {
			padding: 80px 90px;
		}
		@include media-breakpoint-up(xxl) {
			padding: 82px 113px;
		}

		@include content();

	}

}
