/* ==========================================================================
Loader
========================================================================== */
.loader {

  position: absolute;
  left: -10px;
  top: -10px;
  bottom: -10px;
  right: -10px;
  background: rgba(255, 255, 255, .9);
  margin: 0;
  z-index: 9999;
  border: 1px solid #eee;

  padding: 1.5rem;

  display: none;

  &--fixed {
    position: fixed;
    .loader__container{
      align-items: center;
    }
  }

  &--center {
    .loader {
      &__container {
        align-items: center;
      }
    }
  }

  &--end {
    .loader {
      &__container {
        align-items: flex-end;
      }
    }
  }

  &__container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
  }

  &__logo {
    background-image: url('../../images/logo-footer.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    width: 77px;
    height: 79px;

    animation: sk-rotateplane 3s infinite ease-in-out;
    transform-origin: center;

    margin: 0 auto 20px auto;
  }

  &.active {
    display: block;
  }

  p {
    color: #784062;
    font-style: italic;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }

  @media (min-width: 768px) {
    &__container {
      align-items: center;
    }
  }

}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(400px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(400px) rotateX(0deg) rotateY(0deg);
  }
  25% {
    transform: perspective(400px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(400px) rotateX(-180.1deg) rotateY(0deg);
  }
  50% {
    transform: perspective(400px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(400px) rotateX(-180deg) rotateY(-179.9deg);
  }
  75% {
    transform: perspective(400px) rotateX(0deg) rotateY(-179.9deg);
    -webkit-transform: perspective(400px) rotateX(0deg) rotateY(-179.9deg);
  }
  100% {
    transform: perspective(400px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(400px) rotateX(0deg) rotateY(0deg);
  }
}