@import '../_variables';

.parallax-img {
	height: 250px;
	font-size: 0;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	@include media-breakpoint-up(md) {
		height: 220px;
	}
	@include media-breakpoint-up(lg) {
		height: 400px;
	}
	@include media-breakpoint-up(xl) {
		height: 455px;
	}
	@include media-breakpoint-only(md) {
		margin: 0 auto;
		width: calc(100% - 76px);
	}

	& + .cta-block {
		@include media-breakpoint-up(lg) {
			margin-top: -120px;
		}
		@include media-breakpoint-down(sm) {
			margin-top: -90px;
		}
	}
}
