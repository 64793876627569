//
// Hamburgers
//
// Table of Content
// ===============================================
// # Variables
// # Imports
// # Overrides */

// # Variables
// =============================================== */
//$hamburger-padding-x           : 0 !default;
//$hamburger-padding-y           : 0 !default;
//$hamburger-layer-width         : 18px !default;
//$hamburger-layer-height        : 2px !default;
//$hamburger-layer-spacing       : 4px !default;
//$hamburger-layer-color         : #000 !default;
//$hamburger-layer-border-radius : 4px !default;
//$hamburger-hover-opacity       : 0.7 !default;
//$hamburger-active-layer-color  : $hamburger-layer-color !default;
//$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

$hamburger-padding-x           : 15em;
$hamburger-padding-y           : 15em;
$hamburger-layer-width         : 40em;
$hamburger-layer-height        : 4em;
$hamburger-layer-spacing       : 6em;
$hamburger-layer-color         : #000;
$hamburger-layer-border-radius : 4em;
$hamburger-hover-opacity       : 1;
$hamburger-active-layer-color  : $hamburger-layer-color;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;

// # Imports
// =============================================== */
@import '~hamburgers/_sass/hamburgers/hamburgers';

// # Overrides
// =============================================== */
