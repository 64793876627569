// Table of Content
// ===============================================
// # Variables
// # Mixin */

// # Variables
// =============================================== */
// No local variables available

// # Mixin
// =============================================== */

@mixin check-list() {
	&__item,
	li {
		margin: 28px 0;
		padding-left: 80px;
		position: relative;
		line-height: 1.5;
		@include media-breakpoint-down(sm) {
			margin: 20px 0;
			padding-left: 58px;
		}

		&:before {
			position: absolute;
			top: 50%;
			left: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 42px;
			height: 42px;
			font-family: $font-family-icon;
			font-size: 27px;
			color: $primary;
			border: 2px solid $primary;
			border-radius: 50%;
			transform: translateY(-50%);
			content: '\e876';
			@include media-breakpoint-down(sm) {
				width: 34px;
				height: 34px;
				font-size: 22px;
			}
		}
	}
}

@mixin check-list--alt() {
	&__item,
	li {
		padding-left: 70px;
		@include media-breakpoint-up(md) {
			padding-left: 90px;
		}

		&:before {
			width: 55px;
			height: 55px;
			font-size: 36px;
			@include media-breakpoint-down(md) {
				width: 47px;
				height: 47px;
				font-size: 30px;
			}
		}
	}
}
