@import '../_variables';

.search-block {
	position: relative;
	z-index: 2;
	width: 100%;

	form {
		position: relative;
	}

	input {
		padding: 5px 40px 5px 90px;
		width: 100%;
		height: 42px;
		font-size: 12px;
		color: $body-color;
		border-radius: 34px;
		background-color: $white;
		border: 1px solid #eee;

		@include media-breakpoint-up(md) {
			padding: 5px 40px 5px 110px;
			height: 55px;
			font-size: 18px;
		}
		@include media-breakpoint-down(sm) {
			background-size: 18px;
			box-shadow: 0 14px 41px rgba(0, 0, 0, 0.13);
		}

		&::placeholder {
			color: $body-color;
		}
	}

	&__submit-btn {
		padding: 5px 10px 5px 15px;
		position: absolute;
		top: 0;
		left: 0;
		width: 75px;
		height: 100%;
		font-size: 14px;
		font-weight: 600;
		text-align: center;
		color: $white;
		border: 0;
		border-radius: 34px 0 15px 34px;
		background: $success;
		cursor: pointer;
		@include media-breakpoint-up(md) {
			width: 85px;
			font-size: 18px;
		}
	}

	&__search-icon {

		position: absolute;
		right: 0;
		bottom: 0;

		height: 100%;

		display: flex;
		align-items: center;
		justify-content: center;

		background-image: url('../../images/search.svg');
		background-size: 18px;
		background-position: center;
		background-repeat: no-repeat;

		border: 0;
		margin: 0;
		padding: 0 57px 0 0;

		background-color: transparent;

		@include media-breakpoint-up(md) {
			background-size: 24px;
			padding: 0 70px 0 0;
		}
	}

}
