@import '../variables';

@mixin content() {

  > h1 {

    font-size: 22px;

    @include media-breakpoint-up(md) {
      font-size: 33px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 49px;
    }
  }

  > h2 {
    margin-top: 40px;
    font-size: 20px;

    @include media-breakpoint-up(lg) {
      font-size: 25px;
    }

  }

  > h3 {
    margin-top: 35px;
    font-size: 18px;

    @include media-breakpoint-up(lg) {
      font-size: 23px;
    }

  }

  > h4 {
    margin-top: 30px;
    font-size: 16px;

    @include media-breakpoint-up(lg) {
      font-size: 21px;
    }

  }

  >p {
    margin-bottom: 20px;
    font-size: 13px;

    @include media-breakpoint-up(md) {
      font-size: 14px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 16px;
      margin-bottom: 30px;
    }
  }

  > img {
    margin-bottom: 20px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 40px;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 60px;
    }
  }

  > ul {

    @include check-list;
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
      margin-bottom: 70px;
    }

    > li {
      margin: 33px 0;
      line-height: 1.9;
    }
  }

  > .oc-text-red {
    color: #CA2127;
  }

}