@import '../_variables';

.banner {
	padding: 25px 0 115px;
	min-height: 170px;
	background: $gray-100;
	@include media-breakpoint-up(md) {
		padding: 55px 0 95px;
		min-height: 195px;
	}
	@include media-breakpoint-up(lg) {
		padding: 100px 0 145px;
		min-height: 295px;
	}

	&:after {
		position: absolute;
		bottom: 0;
		left: 0;
		display: block;
		opacity: 0.7;
		width: 100%;
		height: 4px;
		background: $white;
		content: '';
	}

	&__image {
		@include media-breakpoint-down(sm) {
			display: none;
		}

		img {
			top: 0;
			left: 0;
			opacity: 0.13;
			width: 100%;
			height: 100%;
			filter: grayscale(100%);
			mix-blend-mode: luminosity;
			object-fit: cover;
		}
	}

	&__element {
		top: -10px;
		right: -50px;
		max-width: 168px;
		@include media-breakpoint-up(md) {
			top: -37px;
			left: -70px;
			max-width: 280px;
		}
		@include media-breakpoint-up(lg) {
			top: -70px;
			left: -20px;
			z-index: 1;
			max-width: 497px;
		}
	}

	&__tel {
		padding-right: 13px;
		padding-left: 13px;
		height: 33px;
		font-size: 12px;
		white-space: nowrap;
		box-shadow: none;

		span {
			margin-right: 8px;
			font-size: 150%;
		}
	}

	&--contact {
		.breadcrumb {
			@include media-breakpoint-down(sm) {
				margin-bottom: 0;
			}
		}
	}

	& + .contact-us,
	& + .product,
	& + .content {
		margin-top: -110px;
		@include media-breakpoint-up(md) {
			margin-top: -90px;
		}
		@include media-breakpoint-up(lg) {
			margin-top: -132px;
		}
	}
}
