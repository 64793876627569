// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */
.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 58px;
	text-transform: uppercase;
	box-shadow: $btn-box-shadow;

	&:active,
	&:focus {
		outline: none;
		box-shadow: $btn-focus-box-shadow;
	}

	&.btn-white {
		background: $white;
		box-shadow: 0 9px 29px rgba($black, 0.17);

		&:focus,
		&:hover {
			background: darken($white, 5%);
		}
	}

	&.btn-outline-light {
		color: $black;
		border: 2px solid $gray-400;
		border-radius: 0;
		background: $white;
		box-shadow: none;

		&:hover {
			color: $white;
			border-color: $black;
			background: $black;
		}
	}
}
