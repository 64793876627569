.media-card {
  display: block;
  width: 100%;
  background-color: white;
  border-radius: 1.5em;
  box-shadow: 0 1em 2em 0 rgba(0, 0, 0, .1);


  &__image-placeholder{
    padding-top: 66.67%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    //opacity: 0.2;
  }

  &__body {
    padding: 2em 1.5em;

    &__title {
      color: $dark !important;
      font-size: 1.2em;
      margin: 0 0 .3em 0;
    }

    &__provider {
      background: $primary;
      color: #fff;
      text-transform: uppercase;
      padding: 0 1em;
      border-radius: .5em;
      align-self: flex-start;
    }

    p {
      font-size: .9em;
    }

    a {
      color: #212529;
    }

    mark {
      padding: 0;
    }

  }

  &__image {
    max-width: 100%;
    border-radius: 1.5em 1.5em 0 0;
  }

  @media(min-width: 576px) {

    display: flex;
    flex-direction: column;
    align-items: stretch;

    &__body {
      flex-grow: 1;
      &__read-more {
        margin-top: auto;
      }

    }

    &__image {
      object-fit: cover;
      object-position: center;
    }
  }

}
