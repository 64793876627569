// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;

	&:before,
	&:after {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}
}

body {
	background-color: $body-bg;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	@include media-breakpoint-down(lg) {
		font-size: 16px;
	}
	@include media-breakpoint-down(md) {
		font-size: 15px;
		line-height: 2;
	}
	@include media-breakpoint-down(sm) {
		font-size: 12px;
		line-height: 1.84;
	}
}

html,
body {
	&.is-active {
		overflow: hidden;
	}
}

h1,
.h1,
h2,
.h2 {
	@include media-breakpoint-down(xl) {
		font-size: 45px;
	}
	@include media-breakpoint-down(lg) {
		font-size: 40px;
	}
	@include media-breakpoint-down(md) {
		font-size: 36px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 22px;
	}
}

h3,
.h3 {
	@include media-breakpoint-down(xl) {
		font-size: 40px;
	}
	@include media-breakpoint-down(lg) {
		font-size: 36px;
	}
	@include media-breakpoint-down(md) {
		font-size: 32px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 20px;
	}
}

h4,
.h4 {
	@include media-breakpoint-down(xl) {
		font-size: 35px;
	}
	@include media-breakpoint-down(lg) {
		font-size: 32px;
	}
	@include media-breakpoint-down(md) {
		font-size: 28px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 18px;
	}
}

h5,
.h5 {
	@include media-breakpoint-down(md) {
		font-size: 26px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 17px;
	}
}

h6,
.h6 {
	@include media-breakpoint-down(md) {
		font-size: 20px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 16px;
	}
}

a {
	transition: $transition-base;
}

img {
	display: block;
	max-width: 100%;
	height: auto;
}

.clear {
	@include clearfix;
}

.wrapper {
	position: relative;
	width: 100%;
	overflow: hidden;
	@include media-breakpoint-up(md) {
		padding-top: 114px;
	}
	@include media-breakpoint-up(lg) {
		padding-top: 120px;
	}
	@include media-breakpoint-up(xl) {
		padding-top: 123px;
	}
	@include media-breakpoint-down(sm) {
		padding-bottom: 70px;
	}
}

.material-icons-round,
.material-icons {
	font-size: inherit;
}

#__bs_notify__ {
	display: none !important;
}
