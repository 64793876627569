@import '../_variables';

.content-slider {
	margin-bottom: 30px;
	@include media-breakpoint-up(lg) {
		margin-bottom: 70px;
		padding-bottom: 120px;
	}
	@include media-breakpoint-down(md) {
		padding-top: 57px;
	}

	.flex-column-reverse {
		@include media-breakpoint-up(md) {
			flex-direction: inherit !important;
		}
	}

	&__holder {
		z-index: 2;
	}

	&__bg {
		top: 0;
		bottom: 72px;
		left: 0;
		width: 100%;
		@include media-breakpoint-up(md) {
			top: 0;
			bottom: 40px;
		}
		@include media-breakpoint-up(lg) {
			top: 45px;
			bottom: 0;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__element {

		bottom: -140px;
		left: -268px;
		z-index: 3;
		max-width: 750px;
		pointer-events:none;

		@include media-breakpoint-up(lg) {
			bottom: -300px;
		}

		@include media-breakpoint-up(xl) {
			max-width: 800px;
		}
		@include media-breakpoint-up(xxl) {
			max-width: 900px;
			bottom: -330px;
		}
		@include media-breakpoint-up(xxxl) {
			max-width: 1070px;
			left: -440px;
			bottom: -420px;
		}
	}

	&__quote {
		padding: 0 32px 50px;
		z-index: 2;
		@include media-breakpoint-up(md) {
			padding: 50px 20px;
			max-width: 600px;
		}
		@include media-breakpoint-up(lg) {
			padding: 50px 20px 100px;
			max-width: 700px;
		}

		img {
			margin-top: -25px;
			margin-bottom: 30px;
			max-width: 115px;
			border: 9px solid $gray-100;
			background-color: $gray-100;

			@include media-breakpoint-up(md) {
				margin-top: 0;
				max-width: 143px;
				border-width: 14px;
			}
			@include media-breakpoint-up(lg) {
				max-width: 169px;
			}
		}

		h4 {
			margin-bottom: 11px;
			font-size: 23px;
			font-weight: 600;
			@include media-breakpoint-up(md) {
				font-size: 27px;
			}
			@include media-breakpoint-up(lg) {
				font-size: 31px;
			}
		}

		p {
			margin-bottom: 30px;
			font-size: 13px;
			@include media-breakpoint-up(md) {
				font-size: 12px;
			}
			@include media-breakpoint-up(lg) {
				font-size: 15px;
			}
		}

		.btn {
			min-width: 208px;
		}
	}

	&__slides {
		overflow: visible;
	}

	&__slides-slide {
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			@include media-breakpoint-down(md) {
				max-height: 372px;
			}
		}
	}
}
