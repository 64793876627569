@import '../_variables';

.card-team {
	padding: 30px 25px;
	width: 100%;
	box-shadow: 0 3px 13px rgba($black, 0.06);

	display: flex;
	flex-direction: column;

	@include media-breakpoint-up(xxl) {
		padding: 30px 40px 35px;
	}

	> img {
		margin: 0 auto 20px;
		height: 105px;
		width: 105px;
		border: 5px solid lighten($black, 96.08);
		border-radius: 50%;
	}

	cite {
		margin-bottom: 5px;
		padding-left: 18px;
		display: block;
		opacity: 0.59;
		font-size: 12px;
		@include media-breakpoint-up(md) {
			padding-left: 5px;
		}
	}

	&__title {
		font-size: 17px;
		font-weight: 500;
		line-height: 1.2;
	}

	&__tel {
		font-size: 12px;

		> div {
			flex: 0 0 30px;
		}

		img {
			margin: 0 10px 0 0;
		}

		a {
			color: $body-color;

			&:hover {
				color: $primary;
			}
		}
	}

	&__channels {
		margin-top: auto;
	}
}
