@import '../_variables';

.breadcrumb {
	position: relative;
	z-index: 1;
	font-size: 9px;
	font-weight: 500;
	text-transform: uppercase;
	@include media-breakpoint-up(lg) {
		font-size: 10px;
	}

	i {
		margin-right: 10px;
		font-size: 120%;
		color: $primary;
		@include media-breakpoint-up(lg) {
			margin-right: 20px;
		}
	}

	.breadcrumb-item {
		&:before {
			color: $black !important;
		}

		&.active {
			color: $black;
		}

		& + .breadcrumb-item {
			&:before {
				color: $primary;
				content: '»';
			}
		}
	}

	a {
		color: $black;

		&:hover {
			color: $primary;
		}
	}
}
