// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */
input {
	appearance: none;
}

.form-group {
	label {
		font-size: 14px;
		font-weight: 600;
		color: $dark;
	}
}

.form-check {
	margin: 10px 0;
	padding-left: 40px;
	position: relative;
	font-size: 13px;
	color: $dark;

	img {
		display: inline-block;
	}

	input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		visibility: hidden;

		&:checked {
			& + label {
				&:before {
					@include background-image(squire, svg, center, no-repeat, 13px);
				}
			}
		}
	}

	label {
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 21px;
			height: 21px;
			border: 1px solid $gray-200;
			content: '';
			cursor: pointer;
		}
	}

	input[type='radio'] {
		& + label {
			&:before {
				border-radius: 50%;
			}
		}

		&:checked {
			& + label {
				&:before {
					@include background-image(ellipse, svg, center, no-repeat, 13px);
				}
			}
		}
	}
}

.form-check-label {
	&--icon {
		padding-left: 40px;

		picture {
			position: absolute;
			top: 0;
			left: 40px;
			width: 22px;
		}
	}
}

textarea {
	appearance: none;

	&.form-control {
		height: 152px;
		appearance: none;
		resize: none;
	}
}

.form-control {
	height: 40px;
	appearance: none;
	@include media-breakpoint-up(md) {
		height: 43px;
	}

	&.is-success {
		border-color: $primary;
		background-clip: border-box;
		background-origin: border-box;
		@include background-image(check, svg, calc(100% + 10px) calc(100% + 20px), no-repeat);
		@include media-breakpoint-down(md) {
			background-position: calc(100% + 16px) calc(100% + 20px);
		}
	}
}
